$(document).ready(function () {

    $('[class*="btn-special"]').each(function () {
        $(this).wrap('<div></div>');

        if ($(this).hasClass('btn-special-left')) {
            $(this).before('<div class="border-special border-special-left-line">&nbsp;</div>');
        } else if ($(this).hasClass('btn-special-right')) {
            $(this).after('<div class="pull-left border-special border-special-right-line">&nbsp;</div>');
        } else if ($(this).hasClass('btn-special-bottom-left')) {
            $(this).after('<div class="border-special border-special-bottom-left-line pull-right">&nbsp;</div>');
        } else if ($(this).hasClass('btn-special-bottom-right')) {
            $(this).after('<div class="border-special border-special-bottom-right-line">&nbsp;</div>');
        }

    });

});