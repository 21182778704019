var map;

function initmap() {
    map = new L.Map('map_leaflet_geo');
    var osmUrl='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
    var osmAttrib='Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors';
    var osm = new L.TileLayer(osmUrl, {minZoom: 3, maxZoom: 16, attribution: osmAttrib});

    map.setView(new L.LatLng(lat, lon),zoom);
    map.addLayer(osm);
    L.marker([lat, lon]).addTo(map);
}

$(document).ready(function () {
    if ($('#map_leaflet_geo').length > 0) {
        L.Icon.Default.imagePath = '../vendor/img/';
        initmap();
    }
})