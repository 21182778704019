/**
 * Created by Darek on 2017-07-17.
 */
$(document).ready(function() {

    $('#calendar').fullCalendar({
        header: {
            left: 'prev,next today',
            center: 'title',
            right: 'agendaDay,agendaWeek,month'
        },
        views: {
            agendaDay: { buttonText: 'Dzień' },
            agendaWeek: { buttonText: 'Tydzień' }
        },
        eventLimit: true,
        events: {
            url: 'calendar_fc',
            cache: true
        }
    })

});