function initialize() {
    var map;
    var bounds = new google.maps.LatLngBounds();

    var myStyle = [
        {
            stylers: [
                {"visibility": "on"},
                {"gamma": 0.77},
                {"saturation": -57},
                {"lightness": 23}
            ]
        }, {
            featureType: "poi",
            stylers: [
                {"visibility": "off"}
            ]
        }
    ];
    var options = {
        mapTypeId: 'mapInfoPoint',
        streetViewControl: false
    };

    map = new google.maps.Map(document.getElementById("map_geo"), options);

    var styledMapOptions = {name: 'Mapa'};
    var myMap = new google.maps.StyledMapType(myStyle, styledMapOptions);
    map.mapTypes.set('mapInfoPoint', myMap);

    var infoWindow = new google.maps.InfoWindow(), marker, i;

    for (i = 0; i < markers.length; i++) {
        var position = new google.maps.LatLng(markers[i][1], markers[i][2]);
        bounds.extend(position);
        marker = new google.maps.Marker({
            position: position,
            map: map,
            title: markers[i][0]
        });

        google.maps.event.addListener(marker, 'click', (function (marker, i) {
            return function () {
                map.setZoom(zoom ? parseInt(zoom) : 14);
                map.setCenter(marker.getPosition());
                infoWindow.setContent(infoWindowContent[i][0]);
                infoWindow.open(map, marker);
            }
        })(marker, i));

        map.fitBounds(bounds);
    }

    var listener = google.maps.event.addListener(map, "idle", function() {
        map.setZoom(zoom ? parseInt(zoom) : 14);
        google.maps.event.removeListener(listener);
    });
}
$(document).ready(function () {
    if ($('#map_geo').length > 0) {
        google.maps.event.addDomListener(window, 'load', initialize);
    }
})