var score = function (btn, scr, id) {

    if ($(btn).hasClass('disabled')) {
        return false;
    }

    $('.btn-score-' + scr).removeClass('btn-primary').addClass('btn-default').addClass('disabled');
    $(btn).removeClass('disabled').addClass('btn-success');
    $('#score' + scr).val(id);
    $(btn).siblings('button').removeClass('btn-primary').addClass('btn-default').addClass('disabled');
    console.debug(btn, scr);

}

$(document).ready(function () {

    $('#vote-form').submit(function (event) {

        var $btnSubmit = $('#btn-vote-submit');
        if ($btnSubmit.hasClass('disabled')) {
            return false;
        }

        $btnSubmit.addClass('disabled');
        var data = $(this).serialize();
        $.post('/vote', data, function (r) {
            var $voteFail = $('.vote-fail');
            var $voteOk = $('.vote-ok');

            if (r.data.result) {
                $voteOk.show();
                $voteFail.hide();
                $('#submit-vote').remove();

            } else {
                $btnSubmit.removeClass('disabled');
                $voteOk.hide();
                $voteFail.show();

                var validation = _.chain(r.validation).map(function (v, k) {
                    return v[0];
                }).join("<br>").value();

                $voteFail.html(validation);
                
                $('#captcha_c').val("");
                $('#captcha_a').text(r.data.captcha.a);
                $('#captcha_b').text(r.data.captcha.b);
                $('#captcha_uuid').val(r.data.captcha.uuid);
            }
        }, "json");
        return false;

    });

    $('#btn-vote-reset').click(function () {
        $(this).closest('#vote-form').find("input[type=text], textarea").val("");
        $('#score1').val("");
        $('#score2').val("");
        $('#score3').val("");
        $('#btn-vote-submit').removeClass('disabled');
        $('.panel.panel-default [type=button]').each(function () {
            $(this).addClass('btn-primary').removeClass('btn-default').removeClass('btn-success').removeClass('disabled');
        });
    });
});