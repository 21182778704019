$(document).ready(function () {

    $("#main-content").find('.mniej-row').hide();

    // $('.wiecej', this).on("click", function() {
    //     $(this).hide();
    //     $(this).parent().parent().parent().find('.mniej-row').show();
    // });
    //
    // $('.mniej', this).on("click", function() {
    //     $(this).parent().parent().find('.mniej-row').hide();
    //     $(this).parent().parent().find('.wiecej').show();
    // });

    $(".plain-panels .cov-inside").on("hide.bs.collapse", function () {
        $(this).parent().find('.wiecej').show();
        $(this).parent().find('.mniej-row').hide();
        window.location = '#' + $(this).parent().parent().find('.anchor').attr('id');
    });
    $(".plain-panels .cov-inside").on("show.bs.collapse", function () {
        $(this).parent().find('.wiecej').hide();
        $(this).parent().find('.mniej-row').show();
    });
});