/**
 * Created by Darek on 2016-11-03.
 */
$(document).ready(function () {

    $(".square").map(function () {
        $(this).height($(this).width() + 15);
    });
    $(".double").map(function () {
        $(this).height(Math.round(($(this).width() + 30) / 2));
    });

    $('#gallery .square').lazyload({
        effect: "fadeIn"
    });

    $('#gallery .double').lazyload({
        effect: "fadeIn"
    });
});