$(document).ready(function () {

    jQuery.ajaxSetup({
        cache: false,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
        }
    });

    $('#comment-form').submit(function () {
        console.log('submit');

        $('.comment-result').children().detach();

        $.post("/comment", $("#comment-form").serialize(), function (r) {

            $('#captcha_a').text(r.data.captcha.a);
            $('#captcha_b').text(r.data.captcha.b);
            $('#captcha_c').val('');
            $('#captcha_uuid').val(r.data.captcha.uuid);

            if (r.data && r.data.result === true) {

                $('.comment-result').append('<div class="alert alert-success">Dziękujemy! Twój komentarz pojawi sie po opublikowaniu go przez moderatora.</div>');
                $('#nick').val('');
                $('#contents').val('');

            } else {

                $('.comment-result').append('<div class="alert alert-danger">Nieudane wysyłanie komentarza.<div class="cause"></div> </div>');

                if (r.validation) {

                    var labels = {
                        captcha: 'Wynik',
                        nick: 'Nick',
                        contents: 'Treść'
                    };

                    $.each(r.validation, function (i, val) {
                        $('.cause').append('<div>' + '[' + labels[i] + '] - ' + val + '</div>');
                    });
                }
            }
        }, 'json');

        return false;
    });

});