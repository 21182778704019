$(document).ready(function () {
    $('.btn-cookie').click(function () {
        $.cookie('beenhere', 1, {expires: 365, path: '/'});
        $('#cookies').hide();
    });

    $(window).on('load',function(){
        if($('#extraModal').length) {
            $('#extraModal').modal('show');
        }
    });

    $('.btn-view-extra-modal').click(function () {
        $.cookie('view_extra_modal', 1, {expires: 365, path: '/'});
    });
});