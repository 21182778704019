$(document).ready(function () {

    $.urlParam = function(name){
        var results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(window.location.href);
        if (!results)
        {
            return '';
        }
        return results[1] || '';
    };

    $('#datatable').DataTable({
        "processing": true,
        "serverSide": true,
        "order": [[ 1, "asc" ]],
        "ajax": "/offers_dt",
        "oSearch": {"sSearch": $.urlParam('t')},
        "columns": [
            { "name": 'id',
                "render": function ( data, type, row ) {
                    console.log(data, type, row);
                return '<a href="content/'+ row[4]+'" title="Zobacz szczegóły"><span class="glyphicon glyphicon-file"></span></a>';
                },
                "orderable": false, "searchable": false
            },
            { "name": 'city', "orderable": true, "searchable": true,},
            { "name": 'tags',
                "render": function ( data, type, row ) {
                    console.log(data, type, row);
                    return data[0].text;
                },
                "orderable": true, "searchable": true,},
            { "name": 'title', "orderable": true, "searchable": true },
            { "data": 6,
                "render": function ( data, type, row ) {
                return row[6].name;
            },
                "orderable": true, "searchable": true
            },
        ],
        "language": {
            "processing": "Przetwarzanie...",
            "search": "Szukaj:",
            "lengthMenu": "Pokaż _MENU_ pozycji",
            "info": "Pozycje od _START_ do _END_ z _TOTAL_ łącznie",
            "infoEmpty": "Pozycji 0 z 0 dostępnych",
            "infoFiltered": "(filtrowanie spośród _MAX_ dostępnych pozycji)",
            "infoPostFix": "",
            "loadingRecords": "Wczytywanie...",
            "zeroRecords": "Nie znaleziono pasujących pozycji",
            "emptyTable": "Brak danych",
            "paginate": {
                "first": "Pierwsza",
                "previous": "Poprzednia",
                "next": "Następna",
                "last": "Ostatnia"
            },
            "aria": {
                "sortAscending": ": aktywuj, by posortować kolumnę rosnąco",
                "sortDescending": ": aktywuj, by posortować kolumnę malejąco"
            }
        }
    });

    $('#datatable-partners').DataTable({
        "processing": true,
        "serverSide": true,
        "order": [[ 1, "asc" ]],
        "ajax": "/partners_dt",
        //"oSearch": {"sSearch": $.urlParam('t')},
        "columns": [
            { "name": 'id',
                "render": function ( data, type, row ) {
                    console.log(data, type, row);
                    return '<a href="partnerOffers/'+ row[0]+'" title="Zobacz oferty tego partnera"><span class="glyphicon glyphicon-th-list"></span></a>';
                },
                "orderable": false, "searchable": false
            },
            { "name": 'name', "orderable": true, "searchable": true,},
            { "name": 'www',
                "render": function ( data, type, row ) {
                    console.log(data, type, row);
                    if(!row[2]) row[2]='';
                    if(row[2].length > 1) {
                        return '<a href="'+ row[2]+'" title="WWW" target="_blank"><span class="glyphicon glyphicon-link"></span></a>';
                    }
                    else {return '';}
                },
                "orderable": false, "searchable": false,},
            { "name": 'desc', "orderable": false, "searchable": true },
        ],
        "language": {
            "processing": "Przetwarzanie...",
            "search": "Szukaj:",
            "lengthMenu": "Pokaż _MENU_ pozycji",
            "info": "Pozycje od _START_ do _END_ z _TOTAL_ łącznie",
            "infoEmpty": "Pozycji 0 z 0 dostępnych",
            "infoFiltered": "(filtrowanie spośród _MAX_ dostępnych pozycji)",
            "infoPostFix": "",
            "loadingRecords": "Wczytywanie...",
            "zeroRecords": "Nie znaleziono pasujących pozycji",
            "emptyTable": "Brak danych",
            "paginate": {
                "first": "Pierwsza",
                "previous": "Poprzednia",
                "next": "Następna",
                "last": "Ostatnia"
            },
            "aria": {
                "sortAscending": ": aktywuj, by posortować kolumnę rosnąco",
                "sortDescending": ": aktywuj, by posortować kolumnę malejąco"
            }
        }
    });
});